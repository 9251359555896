




















































































































































































































import { Component, Prop, Vue } from 'vue-property-decorator';
import WhiteLogoSVG from '!vue-svg-loader!@/assets/logo_white.svg';
import { getModule } from 'vuex-module-decorators';
import { GuiModule } from '@/store/modules/gui';
import { VueConstructor } from 'vue';

import { SettingsModule } from '../store/modules/settings';
import { logout, errorEventBus } from '../service';
import { getPlayer, destroyPlayer } from '../service/audio';
import { PlayerModule } from '../store/modules/player';
import { Track } from '../service/types';
import { getWaveform } from '../service/api';
import { UserModule } from '../store/modules/user';
import { sleep } from '../util';

import CompactPlayerControls from '@/components/CompactPlayerControls.vue';
import PlayerControls from '@/components/PlayerControls.vue';

interface MenuEntry {
  title: string;
  routePath: string;
  icon?: string;
}

@Component({
  components: { WhiteLogoSVG, CompactPlayerControls, PlayerControls }
})
export default class App extends Vue {
  shouldUpdatePlayer: boolean = true;

  get drawer() {
    const gui = getModule(GuiModule, this.$store);

    return gui.drawer && gui.drawerEnabled;
  }

  set drawer(on: boolean | null) {
    const gui = getModule(GuiModule, this.$store);

    if (!gui.drawerEnabled) {
      return;
    }

    gui.toggleDrawer(on ?? false);
  }

  get drawerEnabled() {
    const gui = getModule(GuiModule, this.$store);

    return gui.drawerEnabled;
  }

  get rightDrawer() {
    const gui = getModule(GuiModule, this.$store);

    return gui.rightDrawer && gui.rightDrawerEnabled;
  }

  set rightDrawer(on: boolean | null) {
    const gui = getModule(GuiModule, this.$store);

    if (!gui.rightDrawerEnabled) {
      return;
    }

    gui.setRightDrawer(on ?? false);
  }

  get rightDrawerEnabled() {
    const gui = getModule(GuiModule, this.$store);

    return gui.rightDrawerEnabled;
  }

  get bottomPlayer() {
    const settings = getModule(SettingsModule, this.$store);

    return settings.bottomPlayer;
  }

  set bottomPlayer(val) {
    const settings = getModule(SettingsModule, this.$store);

    settings.setBottomPlayer(val);
  }

  // Only for mobile
  get menuEntries(): MenuEntry[] {
    return [
      {
        title: this.$t(`app.menu.playlists`).toString(),
        icon: 'mdi-playlist-plus',
        routePath: '/app/playlists/browse'
      },
      {
        title: this.$t(`app.menu.tracks`).toString(),
        icon: 'mdi-playlist-music',
        routePath: '/app/tracks'
      },
      {
        title: this.$t(`app.menu.player`).toString(),
        icon: 'mdi-play-circle-outline',
        routePath: '/app/player'
      }
    ];
  }

  async logout() {
    await logout();
    window.location.href = '/login';
  }

  toggleTheme() {
    const settings = getModule(SettingsModule, this.$store);

    settings.toggleDarkTheme(!settings.darkTheme);

    this.$vuetify.theme.dark = settings.darkTheme;
  }

  async updatePlayerTime() {
    const playerStore = getModule(PlayerModule, this.$store);

    if (!this.shouldUpdatePlayer) {
      return;
    }

    const player = await getPlayer();
    if (!player.destroyed) {
      playerStore.setPosition(player.seek);
    }

    requestAnimationFrame(this.updatePlayerTime);
  }

  async created() {
    const player = await getPlayer();
    const gui = getModule(GuiModule, this.$store);
    const playerStore = getModule(PlayerModule, this.$store);

    player.volume = playerStore.volume;
    gui.setPlaying(false);

    player.onLoadStart(async (track: Track) => {
      playerStore.setLoading(true);

      gui.setWaveform(null);
      gui.setWaveform(await getWaveform(track));
    });

    player.onLoad(() => {
      playerStore.setLoading(false);
    });

    player.onPause(() => {
      gui.setPlaying(false);
    });

    player.onEnd(async () => {
      gui.setPlaying(false);

      if (
        playerStore.currentTrackIndex !== null &&
        playerStore.tracks.length > 0
      ) {
        if (playerStore.currentTrackIndex < playerStore.tracks.length - 1) {
          // Play the next track
          playerStore.setcurrentTrackIndex(playerStore.currentTrackIndex + 1);
          await player.load(playerStore.tracks[playerStore.currentTrackIndex]);
          await player.play();
        } else {
          // End of the playback
          playerStore.setcurrentTrackIndex(0);
          await player.load(playerStore.tracks[0]);
        }
      }
    });

    player.onPlay(() => {
      gui.setPlaying(true);
    });

    player.onError((error) => {
      player.stop();

      if (error) {
        return errorEventBus.$emit('error', error);
      }

      errorEventBus.$emit('error', new Error('unknown audio playback error'));
    });

    if (playerStore.muted) {
      player.volume = 0;
    } else {
      player.volume = playerStore.volume;
    }

    if (playerStore.currentTrackIndex !== null) {
      const track = playerStore.tracks[playerStore.currentTrackIndex];

      if (track) {
        await player.load(playerStore.tracks[playerStore.currentTrackIndex]);
        player.seek = playerStore.position;
      }
    }

    if (this.$vuetify.breakpoint.smAndDown) {
      this.bottomPlayer = false;
    }

    this.updatePlayerTime();
  }

  async onKeyPressed(event: KeyboardEvent) {
    if (event.which === 32) {
      const player = await getPlayer();
      if (player.playing) {
        player.pause();
      } else {
        player.play();
      }
    }
  }

  beforeMount() {
    const gui = getModule(GuiModule, this.$store);

    // window.addEventListener('keypress', this.onKeyPressed);

    if (this.$vuetify.breakpoint.mdAndDown) {
      gui.toggleDrawer(false);
    }
  }

  async beforeDestroy() {
    // window.removeEventListener('keypress', this.onKeyPressed);
    await destroyPlayer();
    this.shouldUpdatePlayer = false;
  }
}
