var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pa-0 d-flex flex-row flex-nowrap align-center justify-center"},[_c('v-tooltip',{attrs:{"open-delay":500,"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"disabled":_vm.firstTrack || _vm.playerLoading || !_vm.currentTrack,"icon":"","dark":""},on:{"click":_vm.previousTrack}},on),[_c('v-icon',[_vm._v("mdi-skip-previous")])],1)]}}])},[_vm._v(" "+_vm._s(_vm.$t('app.player.previous'))+" ")]),_c('v-spacer'),(!_vm.playing)?_c('v-tooltip',{attrs:{"open-delay":500,"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"loading":_vm.playerLoading,"disabled":!_vm.currentTrack,"icon":"","dark":""},on:{"click":_vm.play}},on),[_c('v-icon',[_vm._v("mdi-play")])],1)]}}],null,false,3268793932)},[_vm._v(" "+_vm._s(_vm.$t('app.player.play'))+" ")]):_c('v-tooltip',{attrs:{"open-delay":500,"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"loading":_vm.playerLoading,"disabled":!_vm.currentTrack,"icon":"","dark":""},on:{"click":_vm.pause}},on),[_c('v-icon',[_vm._v("mdi-pause")])],1)]}}])},[_vm._v(" "+_vm._s(_vm.$t('app.player.pause'))+" ")]),_c('v-spacer'),_c('v-tooltip',{attrs:{"open-delay":500,"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"disabled":_vm.lastTrack || _vm.playerLoading || !_vm.currentTrack,"icon":"","dark":""},on:{"click":_vm.nextTrack}},on),[_c('v-icon',[_vm._v("mdi-skip-next")])],1)]}}])},[_vm._v(" "+_vm._s(_vm.$t('app.player.next'))+" ")]),_c('v-tooltip',{attrs:{"open-delay":500,"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"flex-shrink-1 text-wrap d-inline-block",attrs:{"text":"","dark":""},on:{"click":function($event){return _vm.$emit('titleClicked')}}},on),[_vm._v(" "+_vm._s(_vm.title)+" ")])]}}])},[_vm._v(" "+_vm._s(_vm.$t('app.player.openPlayer'))+" ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }