var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"fill-height",attrs:{"fluid":""}},[_c('v-app-bar',{attrs:{"app":"","clipped-left":"","clipped-right":"","color":"primary","dark":""}},[(_vm.drawerEnabled)?_c('v-app-bar-nav-icon',{on:{"click":function($event){$event.stopPropagation();_vm.drawer = !_vm.drawer}}}):_vm._e(),_c('WhiteLogoSVG',{staticClass:"toolbar-logo"}),_c('v-spacer'),_c('v-toolbar-items',{staticClass:"hidden-sm-and-down mr-0"},[_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({class:{
              'v-btn--active': _vm.$route.path.startsWith('/app/playlists')
            },attrs:{"text":""}},on),[_c('v-icon',{staticClass:"mr-1"},[_vm._v("mdi-playlist-music")]),_vm._v(" "+_vm._s(_vm.$t("app.menu.playlists"))+" ")],1)]}}])},[_c('v-list',[_c('v-list-item',{attrs:{"to":"/app/playlists/browse"}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-playlist-star")])],1),_c('v-list-item-title',{staticClass:"text-uppercase"},[_vm._v(" "+_vm._s(_vm.$t('app.menu.browsePlaylists'))+" ")])],1),_c('v-list-item',{attrs:{"to":"/app/playlists/edit"}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-playlist-edit")])],1),_c('v-list-item-title',{staticClass:"text-uppercase"},[_vm._v(" "+_vm._s(_vm.$t('app.menu.editPlaylists'))+" ")])],1)],1)],1),_c('v-btn',{attrs:{"to":"/app/tracks","text":""}},[_c('v-icon',{staticClass:"mr-1"},[_vm._v("mdi-music-box")]),_vm._v(" "+_vm._s(_vm.$t("app.menu.tracks"))+" ")],1),_c('v-divider',{attrs:{"vertical":""}}),_c('v-btn',{attrs:{"to":"/app/player","text":""}},[_c('v-icon',{staticClass:"mr-1"},[_vm._v("mdi-play-circle-outline")]),_vm._v(" "+_vm._s(_vm.$t("app.menu.player"))+" ")],1),_c('v-divider',{attrs:{"vertical":""}}),_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"text":""}},on),[_c('v-icon',{staticClass:"mr-1"},[_vm._v("mdi-account-circle")])],1)]}}])},[_c('v-list',[_c('v-list-item',{on:{"click":_vm.logout}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-logout")])],1),_c('v-list-item-title',{staticClass:"text-uppercase"},[_vm._v(" "+_vm._s(_vm.$t('app.menu.logout'))+" ")])],1)],1)],1)],1),_c('v-menu',{attrs:{"bottom":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"d-md-none",attrs:{"text":"","icon":"","color":"white"}},on),[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)]}}])},[_c('v-list',[_vm._l((_vm.menuEntries),function(entry){return _c('v-list-item',{key:entry.routePath,attrs:{"to":entry.routePath}},[(entry.icon)?_c('v-list-item-icon',[_c('v-icon',[_vm._v(_vm._s(entry.icon))])],1):_vm._e(),_c('v-list-item-title',[_vm._v(_vm._s(entry.title))])],1)}),_c('v-list-item',{attrs:{"color":"secondary"},on:{"click":_vm.logout}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-logout")])],1),_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t('app.menu.logout')))])],1)],2)],1),(_vm.rightDrawerEnabled)?_c('v-app-bar-nav-icon',{on:{"click":function($event){$event.stopPropagation();_vm.rightDrawer = !_vm.rightDrawer}}}):_vm._e()],1),_c('v-navigation-drawer',{attrs:{"disable-resize-watcher":"","touchless":!_vm.drawerEnabled,"clipped":"","app":""},model:{value:(_vm.drawer),callback:function ($$v) {_vm.drawer=$$v},expression:"drawer"}},[_c('portal-target',{attrs:{"name":"drawer"}})],1),_c('v-navigation-drawer',{attrs:{"disable-resize-watcher":"","touchless":!_vm.rightDrawerEnabled,"clipped":"","right":"","app":""},model:{value:(_vm.rightDrawer),callback:function ($$v) {_vm.rightDrawer=$$v},expression:"rightDrawer"}},[_c('portal-target',{attrs:{"name":"rightDrawer"}})],1),_c('v-content',{staticClass:"fill-height"},[(_vm.bottomPlayer && _vm.$route.name !== 'player')?[_c('div',{style:({
          height: '85%',
          width: '100%'
        })},[_c('router-view')],1),_c('div',{staticClass:"elevation-6",style:({
          height: '15%',
          width: '100%',
          bottom: 0,
          left: 0
        })},[_c('PlayerControls',{style:({ height: '100%' })})],1)]:_c('router-view')],2),_c('v-footer',{staticClass:"white--text flex-nowrap",attrs:{"app":"","color":"cc-blue"}},[_c('v-tooltip',{attrs:{"right":"","attach":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mr-2",attrs:{"text":"","icon":"","color":"white"},on:{"click":_vm.toggleTheme}},on),[_c('v-icon',[_vm._v("mdi-brightness-6")])],1)]}}])},[_vm._v(" "+_vm._s(_vm.$t('app.changeTheme'))+" ")]),(_vm.$route.name !== 'player' && _vm.$vuetify.breakpoint.mdAndUp)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
return [(!_vm.bottomPlayer)?_c('v-btn',_vm._g({attrs:{"icon":"","dark":""},on:{"click":function($event){_vm.bottomPlayer = !_vm.bottomPlayer}}},on),[_c('v-icon',[_vm._v("mdi-arrow-expand-up")])],1):_c('v-btn',_vm._g({attrs:{"icon":"","dark":""},on:{"click":function($event){_vm.bottomPlayer = !_vm.bottomPlayer}}},on),[_c('v-icon',[_vm._v("mdi-arrow-collapse-down")])],1)]}}],null,false,1493209980)},[_vm._v(" "+_vm._s(_vm.$t('app.toggleBottomPlayer'))+" ")]):_vm._e(),(_vm.$route.name !== 'player' && !_vm.bottomPlayer)?_c('CompactPlayerControls',{on:{"titleClicked":function($event){_vm.$route.path !== '/app/player'
          ? _vm.$router.push('/app/player')
          : undefined}}}):_vm._e(),_c('v-spacer'),_c('span',{staticClass:"hidden-sm-and-down"},[_vm._v("CloudCasting © 2020")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }